import React, {Component} from 'react';
import logoLight from '../../assets/img/logo.png'

class Logo extends Component {
    render() {
        return (

            <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="Hireco" rel="home">
                <img id="logo-img" height="48" width="198" className="img-fluid auto_size" src={logoLight} alt="logo-img"/>
             </a>
        );
    }
}

export default Logo;